export const postPendingPixel = async (gameId, canvasId, color, row, col, painter) => {
  const postOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      gameId: gameId,
      canvasId: canvasId,
      color: color,
      row: row,
      col: col,
      painter: painter,
    }),
  };
  fetch(
    "https://pixel-painter-api.vercel.app/api/games/" +
      gameId +
      "/canvases/" +
      canvasId +
      "/pixels/pending",
    postOptions
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    });

  return undefined;
};
