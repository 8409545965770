import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import MKTypography from "components/MKTypography";
import {
  FungibleConditionCode,
  uintCV,
  principalCV,
  AnchorMode,
  PostConditionMode,
  makeStandardSTXPostCondition,
} from "@stacks/transactions";
import { useCallback, useState } from "react";
import { userSession } from "components/ConnectWallet";
import { openContractCall } from "@stacks/connect";
import { StacksTestnet } from "@stacks/network";
import PropTypes from "prop-types";
import Row from "components/Row";
import "../Canvas/canvas.scss";
import AddressViewer from "components/AddressViewer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Canvas = ({ game, canvas, brushes, width, height, selectedColor }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");

  const handleClose = () => setShowConfirmation(false);

  const handleClaimCanvas = (deployer, name, canvasId, fee) => {
    console.log(canvasId);
    console.log(fee);
    claimCanvas(deployer, name, canvasId, fee);
  };

  const claimCanvas = useCallback(async (deployer, name, canvasId, fee) => {
    // With a contract principal
    const postConditionCode = FungibleConditionCode.LessEqual;
    const postConditionAmount = BigInt(fee);
    const postConditionAddress = userSession.loadUserData().profile.stxAddress.testnet;
    const contractAddress = deployer;
    const contractName = name;

    const contractSTXPostCondition = makeStandardSTXPostCondition(
      postConditionAddress,
      postConditionCode,
      postConditionAmount
    );

    const network = new StacksTestnet();
    const options = {
      network: network,
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: "claim-painting",
      functionArgs: [
        uintCV(canvasId),
        uintCV(fee),
        principalCV(userSession.loadUserData().profile.stxAddress.testnet),
      ],
      postConditions: [contractSTXPostCondition],
      anchorMode: AnchorMode.Any,
      postConditionMode: PostConditionMode.Deny,
      onFinish: (data) => {
        setConfirmationTx(
          "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
        );
        setShowConfirmation(true);
      },
    };

    await openContractCall(options);
  }, []);

  let rows = [];
  for (let i = 1; i <= height; i++) {
    rows.push(
      <Row
        key={i}
        game={game}
        canvas={canvas}
        row={i}
        width={width}
        selectedColor={selectedColor}
      />
    );
  }

  return (
    <div id="canvas">
      <MKTypography color="info" variant="h5">
        {canvas != undefined ? canvas.name : "Canvas Not Found"}
      </MKTypography>
      {canvas != undefined && canvas.strokeCount >= canvas.minStrokesRequired ? (
        <MKBox item mb={2}>
          {canvas.owner != undefined ? (
            <>
              <MKTypography variant="body2" color="info">
                Owner:
              </MKTypography>
              <AddressViewer address={canvas.owner} length={16} />
            </>
          ) : (
            <MKButton
              color="info"
              size="small"
              onClick={() =>
                handleClaimCanvas(
                  game.contractDeployer,
                  game.contractName,
                  parseInt(canvas.canvasId),
                  parseInt(canvas.fee)
                )
              }
            >
              Claim Canvas
            </MKButton>
          )}
        </MKBox>
      ) : (
        <MKBox item mb={2}>
          <MKTypography variant="body2" color="info">
            Strokes Until Claim Opens:&nbsp;
            {canvas != undefined
              ? parseInt(canvas.minStrokesRequired) - parseInt(canvas.strokeCount)
              : 0}
          </MKTypography>
        </MKBox>
      )}
      <MKBox mb={1}>
        <div id="rows">{rows}</div>
      </MKBox>
      <MKTypography mb={2} variant="body2" color="info">
        Brush Strokes Remaining:&nbsp;{brushes != undefined ? brushes.usesRemaining : 0}
      </MKTypography>
      <Modal
        open={showConfirmation}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your activation request transaction has been submitted. You will see this pass in a
            status of pending until the transaction has confirmed.
          </MKTypography>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
    </div>
  );
};

// Typechecking props for the DefaultEventCard
Canvas.propTypes = {
  game: PropTypes.object,
  brushes: PropTypes.object,
  canvas: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  selectedColor: PropTypes.string,
};

export default Canvas;
