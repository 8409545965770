import { CirclePicker } from "react-color";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MKButton from "components/MKButton";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";

function SelectColor({ onColorSelected }) {
  const handleColorChange = (color) => {
    if (color.hex != undefined) {
      onColorSelected(color.hex);
    } else {
      onColorSelected(color);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <CirclePicker onChange={handleColorChange} />
      </Grid>
      <Grid item mt={2} xs={12}>
        <MKButton
          variant="outlined"
          size="small"
          color="info"
          onClick={() => handleColorChange("#fff")}
        >
          <FormatColorResetIcon color={"info"} />
          Erase Pixel
        </MKButton>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Row
SelectColor.propTypes = {
  onColorSelected: PropTypes.func,
};

export default SelectColor;
