import { useQuery } from "react-query";

const getBrushes = async (gameId, address) => {
  let brushes = undefined;
  let remainingPixels = 0;

  await fetch("https://pixel-painter-api.vercel.app/api/games/" + gameId + "/brushes/" + address)
    .then((data) => data.json())
    .then((json) => {
      console.log(json);

      const allBrushes = json;

      for (let i = 0; i < allBrushes.length; i++) {
        const b = allBrushes[i];

        const resultBrush = {
          id: b.id,
          type: parseInt(b.type),
          cost: parseInt(b.cost),
          name: b.name,
          usesRemaining: parseInt(b.uses_remaining),
        };

        remainingPixels = remainingPixels + parseInt(b.uses_remaining);

        if (brushes == undefined) {
          brushes = [];
        }
        brushes.push(resultBrush);
      }
    });

  console.log({ pixelsRemaining: remainingPixels, brushes: brushes });
  return { usesRemaining: remainingPixels, brushes: brushes };
};

export const useBrushesQuery = (gameId, address) =>
  useQuery({
    queryKey: [gameId, address],
    queryFn: () => getBrushes(gameId, address),
    refetchInterval: 60000,
    enabled: gameId != undefined && gameId > 0 && address != undefined && address.length > 0,
  });
