import { useQuery } from "react-query";

const getGame = async (gameId) => {
  let game = undefined;

  await fetch("https://pixel-painter-api.vercel.app/api/games/" + gameId)
    .then((data) => data.json())
    .then((json) => {
      console.log(json);

      const g = json;

      game = {
        id: g.id,
        contractDeployer: g.contract_deployer,
        contractName: g.contract_name,
        assetIdentifier: g.asset_identifier,
        activeCanvasId: g.active_canvas_id,
        name: g.name,
        createdAt: g.created_at,
        updatedAt: g.updated_at,
      };
    });

  console.log(game);
  return game;
};

export const useGameQuery = (gameId) =>
  useQuery({
    queryKey: [gameId],
    refetchInterval: 60000,
    queryFn: () => getGame(gameId),
  });

const getGames = async () => {
  const games = [];

  await fetch("https://pixel-painter-api.vercel.app/api/games")
    .then((data) => data.json())
    .then((json) => {
      console.log(json);

      const allGames = json;

      for (let i = 0; i < allGames.length; i++) {
        const g = allGames[i];

        const resultGame = {
          id: g.id,
          contractDeployer: g.contract_deployer,
          contractName: g.contract_name,
          assetIdentifier: g.asset_identifier,
          name: g.name,
          createdAt: g.created_at,
          updatedAt: g.updated_at,
        };

        games.push(resultGame);
      }
    });

  console.log(games);
  return games;
};

export const useGamesQuery = () =>
  useQuery({
    queryKey: [],
    refetchInterval: 60000,
    queryFn: () => getGames(),
  });
