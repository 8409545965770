import { userSession } from "components/ConnectWallet";
import { openContractCall } from "@stacks/connect";
import { StacksTestnet } from "@stacks/network";
import { uintCV, principalCV, AnchorMode, PostConditionMode } from "@stacks/transactions";
import { stringAscii } from "@stacks/transactions/dist/cl";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import "../Pixel/pixel.scss";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import { postPendingPixel } from "stores/PixelStore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Pixel = ({ game, canvas, row, col, selectedColor }) => {
  const [pixelColor, setPixelColor] = useState("#fff");
  const [oldColor, setOldColor] = useState(pixelColor);
  const [canChangeColor, setCanChangeColor] = useState(true);
  const [overridePixel, setOverridePixel] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pixelPainted, setPixelPainted] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");

  const pixel =
    canvas != undefined &&
    canvas.pixels != undefined &&
    canvas.pixels.length > 0 &&
    canvas.pixels.find((p) => p.row == row && p.col == col);

  const handleClose = () => setShowConfirmation(false);

  const paintPixel = useCallback(
    async (contractAddress, contractName, gameId, canvasId, row, col, color) => {
      console.log(userSession.loadUserData().profile.stxAddress.testnet);
      const network = new StacksTestnet();
      const options = {
        network: network,
        contractAddress: contractAddress,
        contractName: contractName,
        functionName: "paint-pixel",
        functionArgs: [
          uintCV(canvasId),
          uintCV(row),
          uintCV(col),
          stringAscii(color),
          principalCV(userSession.loadUserData().profile.stxAddress.testnet),
        ],
        postConditions: [],
        anchorMode: AnchorMode.Any,
        postConditionMode: PostConditionMode.Deny,
        onFinish: (data) => {
          setConfirmationTx(
            "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
          );
          setShowConfirmation(true);
          setPixelPainted(true);
          postPendingPixel(
            gameId,
            canvasId,
            color,
            row,
            col,
            userSession.loadUserData().profile.stxAddress.testnet
          );
        },
        onCancel: () => {
          resetColor();
        },
      };

      await openContractCall(options);
    },
    []
  );

  function applyColor() {
    setPixelColor(selectedColor);
    setCanChangeColor(false);
    !pixelPainted &&
      paintPixel(
        game.contractDeployer,
        game.contractName,
        canvas.gameId,
        canvas.canvasId,
        row,
        col,
        selectedColor
      );
  }

  function changeColorOnHover() {
    setOverridePixel(true);
    setOldColor(pixelColor);
    setPixelColor(selectedColor);
  }

  function resetColor() {
    if (canChangeColor) {
      setPixelColor(oldColor);
      setOverridePixel(false);
    }

    setCanChangeColor(true);
  }

  return (
    <div
      className="pixel"
      onClick={applyColor}
      onMouseEnter={changeColorOnHover}
      onMouseLeave={resetColor}
      style={{ backgroundColor: pixel && !overridePixel ? pixel.color : pixelColor }}
    >
      <Modal
        open={showConfirmation}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your activation request transaction has been submitted. You will see this pass in a
            status of pending until the transaction has confirmed.
          </MKTypography>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
    </div>
  );
};

// Typechecking props for the Row
Pixel.propTypes = {
  game: PropTypes.object,
  canvas: PropTypes.object,
  row: PropTypes.number,
  col: PropTypes.number,
  selectedColor: PropTypes.string,
};

export default Pixel;
