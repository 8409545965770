import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import MKBox from "components/MKBox";
import Icon from "@mui/material/Icon";
import { userSession } from "components/ConnectWallet";
import { openContractCall } from "@stacks/connect";
import { StacksTestnet } from "@stacks/network";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import {
  FungibleConditionCode,
  uintCV,
  principalCV,
  AnchorMode,
  PostConditionMode,
  makeStandardSTXPostCondition,
} from "@stacks/transactions";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { PropTypes } from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function PurchaseBrush({ game }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");

  const { lobbyId } = useParams();

  const handleClose = () => setShowConfirmation(false);

  const handlePurchaseBrush = (type, fee) => {
    console.log(game);
    purchaseBrush(game.contractDeployer, game.contractName, type, fee);
  };

  const purchaseBrush = useCallback(async (contractDeployer, contractName, type, fee) => {
    // With a contract principal
    const postConditionCode = FungibleConditionCode.LessEqual;
    const postConditionAmount = BigInt(fee);
    const postConditionAddress = userSession.loadUserData().profile.stxAddress.testnet;

    const contractSTXPostCondition = makeStandardSTXPostCondition(
      postConditionAddress,
      postConditionCode,
      postConditionAmount
    );

    const network = new StacksTestnet();
    const options = {
      network: network,
      contractAddress: contractDeployer,
      contractName: contractName,
      functionName: "purchase-brush",
      functionArgs: [
        uintCV(type),
        uintCV(fee),
        principalCV(userSession.loadUserData().profile.stxAddress.testnet),
      ],
      postConditions: [contractSTXPostCondition],
      anchorMode: AnchorMode.Any,
      postConditionMode: PostConditionMode.Deny,
      onFinish: (data) => {
        setConfirmationTx(
          "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
        );
        setShowConfirmation(true);
      },
    };

    await openContractCall(options);
  }, []);

  return (
    <MKBox component="section">
      <Container p={3}>
        <MKTypography variant="h5" color="info">
          Purchase a brush to paint!
        </MKTypography>
        <Grid container justifyContent="center">
          {lobbyId < 3 ? (
            <Grid m={1} item>
              <MKButton color="info" size="small" onClick={() => handlePurchaseBrush(1, 500000)}>
                Basic Brush
                <br />2 Strokes - .5 STX
              </MKButton>
            </Grid>
          ) : (
            <Grid m={1} item>
              <MKButton color="info" size="small" onClick={() => handlePurchaseBrush(4, 500000)}>
                Basic Brush
                <br />
                100 Strokes - .5 STX
              </MKButton>
            </Grid>
          )}
          {lobbyId < 3 ? (
            <Grid m={1} item>
              <MKButton color="info" size="small" onClick={() => handlePurchaseBrush(2, 1000000)}>
                {" "}
                Adv Brush
                <br />5 Strokes - 1 STX
              </MKButton>
            </Grid>
          ) : (
            <Grid m={1} item>
              <MKButton color="info" size="small" onClick={() => handlePurchaseBrush(5, 1000000)}>
                Adv Brush
                <br />
                250 Strokes - 1 STX
              </MKButton>
            </Grid>
          )}
          {lobbyId < 3 ? (
            <Grid m={1} item>
              <MKButton color="info" size="small" onClick={() => handlePurchaseBrush(3, 2000000)}>
                {" "}
                Pro Brush
                <br />
                12 Stokes - 2 STX
              </MKButton>
            </Grid>
          ) : (
            <Grid m={1} item>
              <MKButton color="info" size="small" onClick={() => handlePurchaseBrush(6, 2000000)}>
                Pro Brush
                <br />
                600 Strokes - 2 STX
              </MKButton>
            </Grid>
          )}
        </Grid>
      </Container>
      <Modal
        open={showConfirmation}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your activation request transaction has been submitted. You will see this pass in a
            status of pending until the transaction has confirmed.
          </MKTypography>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
    </MKBox>
  );
}

// Typechecking props for the Row
PurchaseBrush.propTypes = {
  game: PropTypes.object,
};

export default PurchaseBrush;
