import { AppConfig, showConnect, UserSession } from "@stacks/connect";

import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

export const appConfig = new AppConfig(["store_write", "publish_data"]);
export const userSession = new UserSession({ appConfig });

function authenticate() {
  showConnect({
    appDetails: {
      name: "Block Launcher",
      icon: window.location.origin + "/logo512.png",
    },
    redirectTo: "/",
    onFinish: () => {
      window.location.reload();
    },
    userSession,
  });
}

function disconnect() {
  userSession.signUserOut("/");
}

const ConnectWallet = () => {
  if (userSession.isUserSignedIn()) {
    return (
      <Button className="Disconnect" onClick={disconnect}>
        {userSession.loadUserData().profile.stxAddress.testnet.substring(0, 5) +
          "..." +
          userSession.loadUserData().profile.stxAddress.testnet.substring(37, 41)}
        <Icon fontSize="default">cancel</Icon>
      </Button>
    );
  }

  return (
    <Button className="Connect" onClick={authenticate}>
      Connect
    </Button>
  );
};

export default ConnectWallet;
