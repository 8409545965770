import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MKTypography from "components/MKTypography";
import AddressViewer from "components/AddressViewer";

const PixelHistory = ({ pixels }) => {
  return (
    <div>
      <MKTypography mb={2} variant="body1" fontWeight="bold">
        Canvas History ( {pixels != undefined && pixels.length} pixels painted )
      </MKTypography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <b>Stroke ID</b>
              </TableCell>
              <TableCell align="center">
                <b>Row</b>
              </TableCell>
              <TableCell align="center">
                <b>Column</b>
              </TableCell>
              <TableCell align="center">
                <b>Color</b>
              </TableCell>
              <TableCell align="center">
                <b>Painter</b>
              </TableCell>
              <TableCell align="center">
                <b>Status</b>
              </TableCell>
            </TableRow>
            {pixels != undefined &&
              pixels.length > 0 &&
              pixels
                .toSorted((b1, b2) => b1.updatedAt - b2.updatedAt)
                .map((p) => (
                  <TableRow key={p.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center">
                      <MKTypography variant="caption">{p.id}</MKTypography>
                    </TableCell>
                    <TableCell align="center">
                      <MKTypography variant="caption">{p.row}</MKTypography>
                    </TableCell>
                    <TableCell align="center">
                      <MKTypography variant="caption">{p.col}</MKTypography>
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ border: "1px solid #000", backgroundColor: p.color }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                      <MKTypography variant="caption">&nbsp;{p.color}</MKTypography>
                    </TableCell>
                    <TableCell align="center">
                      <MKTypography variant="caption">
                        <AddressViewer address={p.painter} length={12}></AddressViewer>
                      </MKTypography>
                    </TableCell>
                    <TableCell align="center">
                      <MKTypography variant="caption">
                        {p.status == 0 ? "Confirmed" : "Pending"}
                      </MKTypography>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// Typechecking props for the Row
PixelHistory.propTypes = {
  game: PropTypes.object,
  pixels: PropTypes.object,
};

export default PixelHistory;
