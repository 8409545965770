/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { userSession } from "components/ConnectWallet";
import { openContractCall } from "@stacks/connect";
import { StacksTestnet } from "@stacks/network";
import { AnchorMode, PostConditionMode } from "@stacks/transactions";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import SimpleFooter from "examples/Footers/SimpleFooter";
import Canvas from "components/Canvas";
import MKButton from "components/MKButton";
import { useGameQuery } from "stores/GamesStore";
import { useCanvasQuery } from "stores/CanvasStore";
import { useBrushesQuery } from "stores/BrushesStore";
import SelectColor from "./sections/SelectColor";
import PurchaseBrush from "./sections/PurchaseBrush";
import { useParams } from "react-router-dom";
import PixelHistory from "./sections/PixelHistory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function GameLobby() {
  const navigate = useNavigate();

  //state vars
  const [selectedColor, setSelectedColor] = useState("#ff9800");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTx, setConfirmationTx] = useState("");

  const { lobbyId, canvasId } = useParams();

  const { data: game } = useGameQuery(lobbyId);
  const { data: canvas } = useCanvasQuery(
    lobbyId,
    canvasId != undefined ? canvasId : game != undefined ? game.activeCanvasId : -1
  );
  const { data: brushes } = useBrushesQuery(
    lobbyId,
    userSession.isUserSignedIn() ? userSession.loadUserData().profile.stxAddress.testnet : undefined
  );
  const pixelHistory = canvas != undefined ? canvas.pixels : undefined;

  const handleClose = () => setShowConfirmation(false);

  const handlePrevCanvas = () => {
    console.log("Prev Canvas");
    navigate("/lobby/" + game.id + "/canvas/" + (parseInt(canvasId) - 1));
  };

  const handleNextCanvas = () => {
    console.log("Next Canvas");
    navigate("/lobby/" + game.id + "/canvas/" + (parseInt(canvasId) + 1));
  };

  const handleInitGame = () => {
    initGame(game.contractDeployer, game.contractName);
  };

  const initGame = useCallback(async (contractAddress, contractName) => {
    console.log(userSession.loadUserData().profile.stxAddress.testnet);
    const network = new StacksTestnet();
    const options = {
      network: network,
      contractAddress: contractAddress,
      contractName: contractName,
      functionName: "init-game",
      functionArgs: [],
      postConditions: [],
      anchorMode: AnchorMode.Any,
      postConditionMode: PostConditionMode.Deny,
      onFinish: (data) => {
        setConfirmationTx(
          "https://explorer.hiro.so/txid/" + data.txId.toString() + "?chain=testnet"
        );
        setShowConfirmation(true);
      },
    };

    await openContractCall(options);
  }, []);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ml={{ xs: "auto" }} mr={{ xs: "auto" }}>
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                {game != undefined ? game.name : "Game Not Found"}
              </MKTypography>
            </MKBox>
            <Grid container>
              <Grid item xs={12}>
                <MKBox textAlign="center">
                  <MKTypography variant="caption" fontWeight="bold" color="error">
                    PRIMARY TESTNET v0.0.1
                  </MKTypography>
                </MKBox>
                <MKBox textAlign="center">
                  <MKButton
                    disabled={parseInt(canvasId) <= 1}
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={handlePrevCanvas}
                  >
                    {"<< Prev"}
                  </MKButton>
                  {"    "}
                  <MKButton
                    disabled={
                      parseInt(canvasId) == parseInt(game != undefined ? game.activeCanvasId : 0)
                    }
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={handleNextCanvas}
                  >
                    {"Next >>"}
                  </MKButton>
                  {canvas == undefined && (
                    <MKBox mb={2}>
                      <MKButton onClick={handleInitGame}>Initialize Game Lobby</MKButton>
                    </MKBox>
                  )}
                  <MKBox mt={2}>
                    <Canvas
                      game={game}
                      brushes={brushes}
                      canvas={canvas}
                      width={canvas != undefined ? parseInt(canvas.size) : 0}
                      height={canvas != undefined ? parseInt(canvas.size) : 0}
                      selectedColor={selectedColor}
                    />
                  </MKBox>
                  <MKBox mb={3}>
                    {canvas != undefined &&
                      (canvas.owner == undefined || canvas.owner.length <= 0) && (
                        <SelectColor onColorSelected={(c) => setSelectedColor(c)} />
                      )}
                  </MKBox>
                  <MKBox mb={4}>
                    {canvas != undefined &&
                      (brushes == undefined || brushes.usesRemaining == 0) &&
                      game != undefined && <PurchaseBrush game={game} />}
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid
                item
                sm={12}
                sx={{ display: { xs: "none", smm: "none", md: "none", lg: "block" } }}
              >
                <MKBox textAlign="center" mt={4} p={2}>
                  <PixelHistory pixels={pixelHistory} />
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
      <Modal
        open={showConfirmation}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <MKTypography id="modal-modal-title" variant="h5" component="h2">
            Transaction Submitted
          </MKTypography>
          <MKTypography variant="body2" sx={{ mt: 2 }}>
            Your activation request transaction has been submitted. You will see this pass in a
            status of pending until the transaction has confirmed.
          </MKTypography>
          <MKTypography
            component="a"
            target="_blank"
            href={confirmationTx}
            variant="body1"
            fontWeight="light"
            color="info"
            my={1}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Click to see it in the Explorer&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </MKBox>
      </Modal>
    </>
  );
}

export default GameLobby;
