import { useQuery } from "react-query";

const getCanvases = async (gameId) => {
  const canvases = [];

  await fetch("https://pixel-painter-api.vercel.app/api/games/" + gameId + "/canvases")
    .then((data) => data.json())
    .then((json) => {
      console.log(json);

      const allCanvases = json;

      for (let i = 0; i < allCanvases.length; i++) {
        const c = allCanvases[i];

        const resultCanvas = {
          id: c.id,
          gameId: c.game_id,
          canvasId: c.canvas_id,
          blockCreated: c.block_created,
          name: c.name,
          duration: c.duration,
          size: c.size,
          strokeCount: c.stroke_count,
          minStrokesRequired: c.min_strokes_required,
          fee: c.minClaimFee,
          createdAt: c.created_at,
          updatedAt: c.updatedAt,
        };

        canvases.push(resultCanvas);
      }
    });

  console.log(canvases);
  return canvases;
};

export const useCanvasesQuery = (gameId) =>
  useQuery({
    queryKey: [gameId],
    queryFn: () => getCanvases(gameId),
    refetchInterval: 10000,
    enabled: gameId > 0,
  });

const getCanvas = async (gameId, canvasId) => {
  let resultCanvas = {};

  await fetch(
    "https://pixel-painter-api.vercel.app/api/games/" +
      gameId +
      "/canvases/" +
      canvasId +
      "/?includePixels=true"
  )
    .then((data) => data.json())
    .then((json) => {
      console.log(json);
      const c = json;

      resultCanvas = {
        id: c.id,
        gameId: c.game_id,
        canvasId: c.canvas_id,
        blockCreated: c.block_created,
        name: c.name,
        duration: c.duration,
        size: c.size,
        strokeCount: c.stroke_count,
        minStrokesRequired: c.min_strokes_required,
        fee: c.min_claim_fee,
        owner: c.owner,
        createdAt: c.created_at,
        updatedAt: c.updated_at,
        pixels: [],
      };

      for (let i = 0; i < c.pixels.length; i++) {
        const p = c.pixels[i];

        const pixel = {
          id: p.id,
          row: p.row,
          col: p.col,
          color: p.color,
          status: p.status,
          painter: p.painter,
          createdAt: p.created_at,
          updatedAt: p.updated_at,
        };

        resultCanvas.pixels.push(pixel);
      }
    });

  console.log(resultCanvas);
  return resultCanvas;
};

export const useCanvasQuery = (gameId, canvasId) =>
  useQuery({
    queryKey: [gameId, canvasId],
    queryFn: () => getCanvas(gameId, canvasId),
    refetchInterval: 10000,
    enabled: gameId > 0 && canvasId > 0,
  });
