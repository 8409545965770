import PropTypes from "prop-types";
import Pixel from "components/Pixel";
import "../Row/row.scss";

const Row = ({ game, canvas, row, width, selectedColor }) => {
  let pixels = [];

  for (let i = 1; i <= width; i++) {
    pixels.push(
      <Pixel key={i} game={game} canvas={canvas} row={row} col={i} selectedColor={selectedColor} />
    );
  }

  return <div className="row">{pixels}</div>;
};

// Typechecking props for the Row
Row.propTypes = {
  game: PropTypes.object,
  canvas: PropTypes.object,
  row: PropTypes.number,
  width: PropTypes.number,
  selectedColor: PropTypes.string,
};

export default Row;
